export default {
    "Language": "中文",
    "网络异常, 请稍后再试":"网络异常, 请稍后再试",
    "乐天":"乐天",
    "雅虎":"雅虎",
    "请选择分类":"请选择分类",
    "评论":"评论",
    "请输入内容":"请输入内容",
    "物流信息":"物流信息",
    "去支付":"去支付",
    "商品名称":"商品名称",
    "活动按期开始":"活动按期开始",
    "通过审核":"通过审核",
    "创建成功":"创建成功",
    "支付完成":"支付完成",
    "详情":"详情",
    "提交订单":"提交订单",
    "支付订单":"支付订单",
    "订单完成":"订单完成",
    "当前页":"当前页",
    "购物车":"购物车",
    "联系客服":"联系客服",
    "消息动态":"消息动态",
    "商品列表":"商品列表",
    "包裹列表":"包裹列表",
    "明细 · 提款":"明细 · 提款",
    "账号设置":"账号设置",
    "返回成功":"返回成功",
    "密码":"密码",
    "输入验证码":"输入验证码",
    "确认密码":"确认密码",
    "十五分到了":"十五分到了",
    "登录失败":"登录失败",
    "登录超时":"登录超时",
    "欢迎登录":"欢迎登录",
    "忘记密码":"忘记密码",
    "请选择":"请选择",
    "请输入产品名称":"请输入产品名称",
    "请输入产品网址":"请输入产品网址",
    "请填写您的最高出价":"请填写您的最高出价",
    "请填写 颜色、尺码、订阅数量，其他产品特征或要求":"颜色、尺码、订阅数量，其他产品特征或要求",
    "周日":'周日',
    "周一":'周一',
    "周二":'周二',
    "周三":"周三",
    "周四":"周四",
    "周五":"周五",
    "周六":"周六 ",
    "今天":"今天",
    "请输入数量":"请输入数量",
    "中国":" ",
    "商品名称商品名称商品名称商品名称":"中国",
    "红色":"红色",
    "黄色":"黄色",
    "黑色":"黑色",
    "雅虎搜索":"雅虎搜索",
    "添加商品到购物车":"添加商品到购物车",
    "添加日本商品网页地址到购物车，并下订单":"添加日本商品网页地址到购物车，并下订单",
    "报价":" ",
    "会帮你与店家联系，确认商品价格，以及是否有货":" ",
    "付款":" ",
    "价格通过确认以后，你就可以付款了":" ",
    "稍等几天":" ",
    "会从你指定的商家那里买到你想要的商品，等待商品到达我们仓库":" ",
    "确定运输方式":" ",
    "商品到达我们仓库以后，根据实际重量，你可以挑选更划算运输方式":" ",
    "付运费":" ",
    "包裹重量以及运输方式确定以后，就可以支付国籍运费了":" ",
    "收货":" ",
    "从我们发货，到你收到货，一般需要花1~2周":" ",
    "新服务】xx教学篇：如何从日本":" ",
    "是日本最大规模的批发电商之一，致力于向大量采购/进货的会员":" ",
    "最低价格":" ",
    "最高价格":" ",
    "浏览量升序":" ",
    "浏览量降序":" ",
    "平均浏览量升序":" ",
    "平均浏览量降序":" ",
    "价格升序":" ",
    "价格降序":" ",
    "更新时间升序":" ",
    "更新时间降序":" ",
    "乐天搜索":" ",
    "未知的价格区间":" ",
    "请输入完整的价格区间":" ",
    "商品商品":" ",
    "请输入优惠券号码":" ",
    "请问您是否已阅读过服务条款呢":" ",
    "支付成功":" ",
    "推荐":" ",
    "价格从低到高":" ",
    "价格从高到低":" ",
    "评论从高到低":" ",
    "新增地址":" ",
    "名字":" ",
    "公司(可选":" ",
    "地址":" ",
    "公寓/套房等(可选":" ",
    "邮政编码":" ",
    "电话":" ",
    "编辑地址":" ",
    "参数错误, 请稍后再试":" ",
    "添加一个网站或竞拍商品链接":" ",
    "在这里写下您的问题或留言，客服将在此回复您":" ",
    "下一页":" ",
    "输入的内容在这里写下您的问题或留言，客服将在此回复您。":" ",
    "在这里写下您的问题或留言，客服将在此回复您。":" ",
    "输入充值金额":" ",
    "提现":" ",
    "可提现金额":" ",
    "提现金额":" ",
    "充值":" ",
    "支付失败, 请稍后再试":" ",
    "支付取消":" ",
    "支付发货":" ",
    "订单详情":" ",
    "全部":" ",
    "待付运费":" ",
    "打包中":" ",
    "完成发送":" ",
    "填写您想获得的赔偿额":" ",
    "待支付":" ",
    "等待入库":" ",
    "可发货":" ",
    "对话框":" ",
    "文字提示":" ",
    "弹出框":" ",
    "卡片":" ",
    "走马灯":" ",
    "折叠面板":" ",
    "请填写赔偿额":" ",
    "包裹创建成功":" ",
    "首页":" ",
    "登录":" ",
    "雅虎购物":" ",
    "乐天购物":" ",
    "产品列表":" ",
    "雅虎商品详情":" ",
    "乐天商品详情":" ",
    "订阅支付":" ",
    "新手指南":" ",
    "重要公告":" ",
    "博客":" ",
    "关于我们":" ",
    "服务说明":" ",
    "个人中心":" ",
    "订单列表":" ",
    "未分类":" ",
    "热门购物网站":" ",
    "乐天市场":" ",
    "日本亚马逊":" ",
    "雅虎竞拍网":" ",
    "其他日本购物网":" ",
    "使用说明":" ",
    "费用说明":" ",
    "付款事宜":" ",
    "商品配送":" ",
    "退换货政策":" ",
    "注意事项":" ",
    "费用计算器":" ",
    "帮助中心":" ",
    "常见问题":" ",
    "联系我们":" ",
    "公司概要":" ",
    "服务条款":" ",
    "隐私权政策":" ",
    "退出登录":" ",
    "我的账户":" ",
    "未付款":" ",
    "帮我买":" ",
    "包裹编码":" ",
    "运输方式":" ",
    "快递单号":" ",
    "商品价格":" ",
    "商品编号":" ",
    "商品":" ",
    "物流":" ",
    "取 消":" ",
    "确 定":" ",
    "国际快递公司":" ",
    "日本运费":" ",
    "国际运费运费":" ",
    "特殊加固费用":" ",
    "保险费":" ",
    "合计":" ",
    "支付方式":" ",
    "账户余额":" ",
    "支付":" ",
    "订单号":" ",
    "添加时间":" ",
    "付款时间":" ",
    "留言":" ",
    "结算中":" ",
    "快递公司":" ",
    "运单号":" ",
    "运费":" ",
    "产品编号":" ",
    "商品网址":" ",
    "立即支付":" ",
    "订单信息":" ",
    "订单编号":" ",
    "购买策略":" ",
    "购买限制或卖空时, 购买可购数量":" ",
    "购买限制或卖空时, 取消订单":" ",
    "订单备注":" ",
    "支付信息":" ",
    "余额支付":" ",
    "基本服务费":" ",
    "拍照服务费":" ",
    "优惠价格":" ",
    "实际支付":" ",
    "支付时间":" ",
    "日本邮费":" ",
    "计算中":" ",
    "提示":" ",
    "日本邮费和包裹国际运费一起支付":" ",
    "产品网址":" ",
    "产品数量":" ",
    "产品来源":" ",
    "拍照服务":" ",
    "未购买":" ",
    "已购买":" ",
    "拍照服务费用":" ",
    "您的位置":" ",
    "最新":" ",
    "上一章":" ",
    "暂无":" ",
    "下一章":" ",
    "没有更多了":" ",
    "亲爱的会员您好":" ",
    "全部订单":" ",
    "待创建包裹":" ",
    "待支付运费":" ",
    "联系我们（官方邮箱":" ",
    "发送":" ",
    "雅虎竞拍/代拍的优点和缺点":" ",
    "怎样通过esenjapan参与雅虎竞拍":" ",
    "估算总价":" ",
    "可否取消竞拍出价":" ",
    "日本其他竞拍网":" ",
    "基础费用":" ",
    "免费基础服务":" ",
    "日本国内的转账费":" ",
    "所有商品合包的费用":" ",
    "重新包装结实的包装费":" ",
    "部分运输保险":" ",
    "免费咨询，免费帮你联系卖家":" ",
    "如何付款":" ",
    "运费试算":" ",
    "支付方式及费用":" ",
    "支付币种":" ",
    "支付所需时间":" ",
    "能否赊账":" ",
    "大小/重量限制":" ",
    "违禁商品":" ",
    "关税":" ",
    "你们什么时候发货":" ",
    "什么是“日本雅虎竞拍网":" ",
    "日本雅虎竞拍网”也简称日拍，是日本最大的竞拍网站，也是全世界最大的竞拍网站之一。每天都会卖出将近500万件商品。卖家可以是个人，也可以是以企业店铺。雅虎竞拍网有大量的货源，以满足不同预算，及客户的各种不同需求":" ",
    "雅虎竞拍的优点与缺点":" ",
    "优点":" ",
    "价格很可能比其他网店更便宜":" ",
    "你很可能在这里找到其他网站没有的稀有商品":" ",
    "缺点":" ",
    "如果你需要商品保证书，就别参加竞拍了，因为竞拍商品没有任何担保":" ",
    "你不能取消出价":" ",
    "如果你赢得了商品，就必须付款":" ",
    "即使商品有任何问题，是不能退换的":" ",
    "如何降低雅虎竞拍/代拍风险":" ",
    "在出价之前，请先确定卖家的信用值，如果差评较多，不建议参加此卖家的竞拍":" ",
    "请不要购入所有免费，或者便宜得离奇的商品（0円/1円)，这种商品卖家很可能会另外收取高额的日本国内运费":" ",
    "如果有任何疑问，请一定询问我们的工作人员":" ",
    "如何通过esenjapan出价参与雅虎竞拍/代拍":" ",
    "首先，你需要存足够的钱到“我的账户”，用来出价雅虎竞拍。如果资金不足，请先完成充值，然后进入":" ",
    "雅虎竞拍网或者进入雅虎竞拍官网,把商品拍卖页面的网址复制到“我的账户":" ",
    "如果我是第一个出价者时该如何出价":" ",
    "以相同的价格或更高的价格投标，这是您的“最高出价”。如果有人出价，雅虎会自动提高出价，直到价格达到最高出价。这叫做“自动出价":" ",
    "如果您是唯一的出价者，而没有人出价，您将以最初的当前价格获胜":" ",
    "如果拍卖的卖家设置“最低赢得价格”，您的出价必须高于最低价格":" ",
    "当我和其他竞拍者同时出价时，会出现什么情况":" ",
    "以相同价格或更高的价格投标。雅虎自动将其超过其他投标人设定为最高出价的价格，而当前价格相应上升。 除非您超标，否则无法找到其他投标人的最高出价":" ",
    "官方邮箱":" ",
    "感谢您在此购物, 如果有其他问题请点击 联系客服":" ",
    "发送验证码":" ",
    "目的国家":" ",
    "产品名称":" ",
    "日元价格(每件":" ",
    "数量":" ",
    "其他":" ",
    "提交":" ",
    "选择目的地":" ",
    "包裹重量":" ",
    "长度":" ",
    "宽度":" ",
    "高度":" ",
    "计算":" ",
    "计算结果":" ",
    "估计价格":" ",
    "运\输时效":" ",
    "商品图片有可能与实物不同，请务必详细阅读商品说明内容":" ",
    "店铺名称":" ",
    "商品状态":" ",
    "非常新":" ",
    "商品原页面":" ",
    "官网上的商品页面":" ",
    "颜色":" ",
    "型号":" ",
    "库存 件":" ",
    "备注":" ",
    "加入购物车":" ",
    "友情提示：商品简介由系统自动翻译完成，内容由商家填写，esenjapan无权负责，如果您对此商品有任何疑问，请一定联系我们。您也可点击查看商品右侧的原始网页":" ",
    "商品详情":" ",
    "国际运费估算":" ",
    "运输时效":" ",
    "新动态":" ",
    "用户评价":" ",
    "筛选区":" ",
    "排序":" ",
    "筛选":" ",
    "价格":" ",
    "确定":" ",
    "订阅内容确认":" ",
    "标准购物方案（每个链接":" ",
    "拍照服务（每个商品":" ",
    "优惠券":" ",
    "保存":" ",
    "已优惠金额：{{ discountedPrice }}元":" ",
    "如优惠券折扣未有反映，请停下付款并联通截图与 客户服务中心 联络":" ",
    "当所订购的复数商品中，出现售罄或购买、数量限制时":" ",
    "仅购买当下可订购的商品或数量（包含可调货/预约商品":" ",
    "取消所有同时付款的订单（不包含可调货/预约商品":" ",
    "商品一览表":" ",
    "商品价格 * 数量":" ",
    "订购内容":" ",
    "商品金额":" ",
    "购物方案费用":" ",
    "订购":" ",
    "运费及其他费用":" ",
    "未确定":" ",
    "日本国内运费":" ",
    "国际运费":" ",
    "包装后确定":" ",
    "根据商品不同，有可能产生其他费用":" ",
    "本站服务条款":" ",
    "订购的须知(重要":" ",
    "订购前须知（重要":" ",
    "部分重要无法提供国际寄送服务，请务必在正式付款前确认":" ",
    "点击此处查看禁运品一览表":" ",
    "本公司无法保证商品的真伪。如出现因诈骗而未能收到货物的情况，公司会给予全额保证":" ",
    "请注意，一旦付款成功，订单便无法取消":" ",
    "本人已阅读服务条款，今后不需要重复提示":" ",
    "账号管理":" ",
    "邮箱":" ",
    "更改":" ",
    "设为默认地址":" ",
    "代购手续费":" ",
    "如果您遇到紧急问题，请点击自助服务解决问题":" ",
    "部分问题对应时间：周一至周五 日本时间":" ",
    "自助服务":" ",
    "您的问题":" ",
    "客服回复":" ",
    "指定付款":" ",
    "日期":" ",
    "操作金额":" ",
    "余额":" ",
    "尽在":" ",
    "打印订单":" ",
    "国际邮费":" ",
    "加固费用":" ",
    "保险额占比":" ",
    "保险费用":" ",
    "赔偿价格":" ",
    "评论内容":" ",
    "商品价值":" ",
    "收货人信息":" ",
    "国家":" ",
    "姓名":" ",
    "公司":" ",
    "公寓":" ",
    "市 省":" ",
    "邮编":" ",
    "手机号码":" ",
    "返回":" ",
    "国际快递单号":" ",
    "创建一个包裹":" ",
    "选择要创建的包裹":" ",
    "取消":" ",
    "增值服务":" ",
    "特殊加固（服务费":" ",
    "保险（赔偿额的":" ",
    "其他要求":" ",
    "提交包裹":" ",
}