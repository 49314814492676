import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
import locale from 'element-ui/lib/locale/lang/en'; // 引入英文语言包

Vue.use(ElementUI, { locale });

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);


import i18n from './components/i18n'
import './static/font/font.css'

import translate from 'i18n-jsautotranslate'
Vue.prototype.$translate = translate;

import api from "./api/api.js";

Vue.prototype.$api = api;


Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')

Vue.filter('dateFormatYmd', function(originVal, ){
    // 先把传参毫秒转化为new Date()
    const dt = new Date(originVal * 1000)
    const y = dt.getFullYear()
    // 月份是从0开始,需要+1
    // +''是把数字转化为字符串,padStart(2,'0')是把字符串设置为2位数,不足2位则在开头加'0'
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    const H = (dt.getHours() + '').padStart(2, '0')
    const i = (dt.getMinutes() + '').padStart(2, '0')

    return `${y}-${m}-${d}`
})

Vue.filter('dateFormat', function(originVal, ){
    if (!originVal) {
        return '-';
    }

    // 先把传参毫秒转化为new Date()
    const dt = new Date(originVal * 1000);
    const y = dt.getFullYear()
    // 月份是从0开始,需要+1
    // +''是把数字转化为字符串,padStart(2,'0')是把字符串设置为2位数,不足2位则在开头加'0'
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    const H = (dt.getHours() + '').padStart(2, '0')
    const i = (dt.getMinutes() + '').padStart(2, '0')
    const s = (dt.getSeconds() + '').padStart(2, '0')

    return `${y}-${m}-${d} ${H}:${i}:${s}`
})