export default {
    "Language": "English",
    "网络异常, 请稍后再试": "Network error, please try again later",
    "乐天": "Rakuten",
    "雅虎": "Yahoo",
    "请选择分类": "Please select a category",
    "评论": "Review",
    "请输入内容": "Please enter content",
    "物流信息": "Shipping information",
    "去支付": "Pay it",
    "商品名称": "Product name",
    "活动按期开始": "Event starts as scheduled",
    "通过审核": "Approved",
    "创建成功": "Creation successful",
    "支付完成": "Payment completed",
    "详情": "Details",
    "提交订单": "Submit order",
    "支付订单": "Pay order",
    "订单完成": "Order completed",
    "当前页": "Current page",
    "购物车": "Shopping cart",
    "联系客服": "Contact customer service",
    "消息动态": "Message",
    "商品列表": "Product list",
    "包裹列表": "Package list",
    "明细 · 提款": "Wallet",
    "账号设置": "Account settings",
    "返回成功": "Return successful",
    "密码": "Password",
    "输入验证码": "Enter verification code",
    "确认密码": "Confirm password",
    "十五分到了": "Fifteen minutes are up",
    "登录失败": "Login failed",
    "登录超时": "Login timed out",
    "欢迎登录": "Welcome to login",
    "忘记密码": "Forgot password",
    "请选择": "Please select",
    "请输入产品名称": "Please enter product name",
    "请输入产品网址": "Please enter product URL",
    "请填写您的最高出价": "Please enter product price or your highest bid",
    "请填写颜色、尺码、订阅数量，其他产品特征或要求": "Please fill in color, size, quantity, and other product features or requirements",
    "周日": "Sunday",
    "周一": "Monday",
    "周二": "Tuesday",
    "周三": "Wednesday",
    "周四": "Thursday",
    "周五": "Friday",
    "周六": "Saturday",
    "今天": "Today",
    "请输入数量": "Please enter quantity",
    "中国": "China",
    "商品名称商品名称商品名称商品名称": "Product name product name product name product name",
    "红色": "Red",
    "黄色": "Yellow",
    "黑色": "Black",
    "雅虎搜索": "Search Yahoo",
    "添加商品到购物车": "Add product to cart",
    "添加日本商品网页地址到购物车，并下订单": "Add Japanese product webpage address to cart and place order",
    "报价": "Quote",
    "会帮你与店家联系，确认商品价格，以及是否有货": "We Will contact the store to confirm product price and availability",
    "付款": "Payment",
    "价格通过确认以后，你就可以付款了": "You can make the payment once the price is confirmed",
    "稍等几天": "Wait a few days",
    "会从你指定的商家那里买到你想要的商品，等待商品到达我们仓库": "We Will purchase the desired product from your specified store and wait for it to arrive at our warehouse",
    "确定运输方式": "Confirm shipping method",
    "商品到达我们仓库以后，根据实际重量，你可以挑选更划算运输方式": "Once the product arrives at our warehouse, you can choose a more cost-effective shipping method based on the actual weight",
    "付运费": "Pay shipping fee",
    "包裹重量以及运输方式确定以后，就可以支付国际运费了": "Once the package weight and shipping method are determined, you can pay the international shipping fee",
    "收货": "Package arrived",
    "从我们发货，到你收到货，一般需要花1~2周": "It generally takes 1-2 weeks from our shipping to you receiving the goods",
    "新服务】xx教学篇：如何从日本": "New service】xx tutorial: How to order from Japan",
    "是日本最大规模的批发电商之一，致力于向大量采购/进货的会员": "It is one of the largest wholesale e-commerce platforms in Japan, dedicated to members who purchase in bulk",
    "最低价格": "Lowest price",
    "最高价格": "Highest price",
    "浏览量升序": "Views ascending",
    "浏览量降序": "Views descending",
    "平均浏览量升序": "Average views ascending",
    "平均浏览量降序": "Average views descending",
    "价格升序": "Price ascending",
    "价格降序": "Price descending",
    "更新时间升序": "Update time ascending",
    "更新时间降序": "Update time descending",
    "乐天搜索": "Search Rakuten",
    "未知的价格区间": "Unknown price range",
    "请输入完整的价格区间": "Please enter a complete price range",
    "商品商品": "Product product",
    "请输入优惠券号码": "Please enter coupon code",
    "请问您是否已阅读过服务条款呢": "Have you read the terms of service?",
    "支付成功": "Payment successful",
    "推荐": "Recommended",
    "价格从低到高": "Price from low to high",
    "价格从高到低": "Price from high to low",
    "评论从高到低": "Reviews from high to low",
    "新增地址": "Add new address",
    "名字": "Name",
    "公司(可选)": "Company (optional)",
    "地址": "Address",
    "公寓/套房等(可选)": "Apartment/Suite, etc. (optional)",
    "邮政编码": "Postal code",
    "电话": "Phone",
    "编辑地址": "Edit address",
    "参数错误, 请稍后再试": "Parameter error, please try again later",
    "添加一个网站或竞拍商品链接": "Add a website or auction product link",
    "在这里写下您的问题或留言，客服将在此回复您": "Write your question or message here, customer service will reply here",
    "下一页": "Next page",
    "输入的内容在这里写下您的问题或留言，客服将在此回复您。": "Write your question or message here, customer service will reply here.",
    "输入充值金额": "Enter recharge amount",
    "提现": "Withdraw",
    "可提现金额": "Withdrawable amount",
    "提现金额": "Withdrawal amount",
    "充值": "Recharge",
    "支付失败, 请稍后再试": "Payment failed, please try again later",
    "支付取消": "Payment cancelled",
    "支付发货": "Pay and ship",
    "订单详情": "Order details",
    "全部": "All",
    "待付运费": "Unpaid",
    "打包中": "Packing",
    "完成发送": "Shipped",
    "填写您想获得的赔偿额": "Fill in the amount of compensation you want to receive",
    "待支付": "Pending",
    "等待入库": "Warehouse",
    "可发货": "Shippable",
    "对话框": "Dialog box",
    "文字提示": "Text prompt",
    "弹出框": "Popup box",
    "卡片": "Card",
    "走马灯": "Carousel",
    "折叠面板": "Collapsible panel",
    "请填写赔偿额": "Please fill in the compensation amount",
    "包裹创建成功": "Parcel created successfully",
    "首页": "Home",
    "登录": "Login",
    "雅虎购物": "Yahoo",
    "乐天购物": "Rakuten",
    "产品列表": "Product list",
    "雅虎商品详情": "Yahoo product details",
    "乐天商品详情": "Rakuten product details",
    "订阅支付": "Subscription payment",
    "新手指南": "User guide",
    "重要公告": "Notice",
    "博客": "Blog",
    "关于我们": "About us",
    "服务说明": "Service description",
    "个人中心": "My account",
    "订单列表": "Order list",
    "未分类": "Uncategorized",
    "热门购物网站": "Popular shopping websites",
    "乐天市场": "Rakuten",
    "日本亚马逊": "Amazon Japan",
    "雅虎竞拍网": "Yahoo Auctions",
    "其他日本购物网": "Other sites",
    "使用说明": "User guide",
    "费用说明": "Fees",
    "付款事宜": "Payment",
    "商品配送": "Shipping",
    "退换货政策": "Return and exchange",
    "注意事项": "Precautions",
    "费用计算器": "Fees calculator",
    "帮助中心": "Help center",
    "常见问题": "FAQ",
    "联系我们": "Contact us",
    "公司概要": "About us",
    "服务条款": "Terms of service",
    "隐私权政策": "Privacy policy",
    "退出登录": "Log out",
    "我的账户": "My account",
    "未付款": "Unpaid",
    "帮我买": "Buy for me",
    "包裹编码": "Package code",
    "运输方式": "Shipping method",
    "快递单号": "Tracking number",
    "商品价格": "Product price",
    "商品编号": "Product code",
    "商品": "Product",
    "物流": "Logistics",
    "取消": "Cancel",
    "确定": "Confirm",
    "国际快递公司": "International courier company",
    "日本运费": "Japan postage",
    "国际运费": "International postage",
    "特殊加固费用": "Special reinforcement fee",
    "保险费": "Insurance",
    "合计": "Total",
    "支付方式": "Payment method",
    "账户余额": "Account balance",
    "支付": "Pay",
    "订单号": "Order number",
    "添加时间": "Add time",
    "付款时间": "Payment time",
    "留言": "Message",
    "结算中": "Settling",
    "快递公司": "Courier company",
    "运单号": "Tracking number",
    "运费": "Postage",
    "产品编号": "Product code",
    "商品网址": "Product URL",
    "立即支付": "Pay now",
    "订单信息": "Order information",
    "订单编号": "Order number",
    "购买策略": "Purchase strategy",
    "购买限制或卖空时, 购买可购数量": "Buy available quantity when there are purchase restrictions or out of stock",
    "购买限制或卖空时, 取消订单": "Cancel order when there are purchase restrictions or out of stock",
    "订单备注": "Order remarks",
    "支付信息": "Payment information",
    "余额支付": "Balance",
    "基本服务费": "Basic service fee",
    "拍照服务费": "Photo service fee",
    "优惠价格": "Discounted price",
    "实际支付": "Actual payment",
    "支付时间": "Payment time",
    "日本邮费": "Japan postage",
    "计算中": "Calculating",
    "提示": "Prompt",
    "日本邮费和包裹国际运费一起支付": "Pay Japan postage and international shipping fee together",
    "产品网址": "Product URL",
    "产品数量": "Product quantity",
    "产品来源": "Product source",
    "拍照服务": "Photo service",
    "未购买": "Not purchased",
    "已购买": "Purchased",
    "拍照服务费用": "Photo service fee",
    "您的位置": "Your location",
    "最新": "Latest",
    "上一章": "Previous chapter",
    "暂无": "None",
    "下一章": "Next chapter",
    "没有更多了": "No more",
    "亲爱的会员您好": "Dear member, hello",
    "全部订单": "All orders",
    "待创建包裹": "Pending parcel creation",
    "待支付运费": "Pending postage",
    "联系我们（官方邮箱": "Contact us (official email",
    "发送": "Send",
    "雅虎竞拍/代拍的优点和缺点": "Pros and cons of Yahoo auction/agent bidding",
    "怎样通过esenjapan参与雅虎竞拍": "How to participate in Yahoo auction through esenjapan",
    "估算总价": "Estimate total price",
    "可否取消竞拍出价": "Can the auction bid be cancelled",
    "日本其他竞拍网": "Other Japanese auction sites",
    "基础费用": "Basic fee",
    "免费基础服务": "Free basic service",
    "日本国内的转账费": "Domestic transfer fee",
    "所有商品合包的费用": "All items consolidated packaging fee",
    "重新包装结实的包装费": "Repacking sturdy packaging fee",
    "部分运输保险": "Partial shipping insurance",
    "免费咨询，免费帮你联系卖家": "Free consultation, free contact with seller",
    "如何付款": "How to pay",
    "运费试算": "Shipping cost estimation",
    "支付方式及费用": "Payment methods and fees",
    "支付币种": "Payment currency",
    "支付所需时间": "Time required for payment",
    "能否赊账": "Can you pay on credit",
    "大小/重量限制": "Size/weight limit",
    "违禁商品": "Prohibited items",
    "关税": "Customs duties",
    "你们什么时候发货": "When do you ship",
    "什么是“日本雅虎竞拍网": "What is 'Japan Yahoo Auction Site",
    "日本雅虎竞拍网”也简称日拍，是日本最大的竞拍网站，也是全世界最大的竞拍网站之一。每天都会卖出将近500万件商品。卖家可以是个人，也可以是以企业店铺。雅虎竞拍网有大量的货源，以满足不同预算，及客户的各种不同需求": "'Japan Yahoo Auction Site', also known as 'Nichipa', is Japan's largest auction site and one of the largest auction sites in the world. Nearly 5 million items are sold every day. Sellers can be individuals or corporate stores. Yahoo Auction Site has a large supply of goods to meet different budgets and various customer needs.",
    "雅虎竞拍的优点与缺点": "Pros and cons of Yahoo auction",
    "优点": "Advantages",
    "价格很可能比其他网店更便宜": "Prices are likely to be cheaper than other online stores",
    "你很可能在这里找到其他网站没有的稀有商品": "You are likely to find rare items that other sites don't have here",
    "缺点": "Disadvantages",
    "如果你需要商品保证书，就别参加竞拍了，因为竞拍商品没有任何担保": "If you need a product warranty, don't participate in auctions, as auction items have no guarantees",
    "你不能取消出价": "You cannot cancel a bid",
    "如果你赢得了商品，就必须付款": "If you win the item, you must pay",
    "即使商品有任何问题，是不能退换的": "Even if there are any issues with the item, it cannot be returned or exchanged",
    "如何降低雅虎竞拍/代拍风险": "How to reduce Yahoo auction/agent bidding risks",
    "在出价之前，请先确定卖家的信用值，如果差评较多，不建议参加此卖家的竞拍": "Before bidding, please check the seller's credit rating. If there are many negative reviews, it is not recommended to participate in this seller's auction",
    "请不要购入所有免费，或者便宜得离奇的商品（0円/1円)，这种商品卖家很可能会另外收取高额的日本国内运费": "Please do not purchase all free or strangely cheap items (0 yen/1 yen), as the seller is likely to charge high domestic shipping fees",
    "如果有任何疑问，请一定询问我们的工作人员": "If you have any questions, be sure to ask our staff",
    "如何通过esenjapan出价参与雅虎竞拍/代拍": "How to bid on Yahoo auction/agent bidding through esenjapan",
    "首先，你需要存足够的钱到“我的账户”，用来出价雅虎竞拍。如果资金不足，请先完成充值，然后进入": "First, you need to deposit enough money into 'My Account' to bid on Yahoo auctions. If the funds are insufficient, please complete the recharge first, then enter",
    "雅虎竞拍网或者进入雅虎竞拍官网,把商品拍卖页面的网址复制到“我的账户": "Yahoo Auction Site or enter Yahoo Auction official website, copy the product auction page URL to 'My Account'",
    "如果我是第一个出价者时该如何出价": "How to bid if I am the first bidder",
    "以相同的价格或更高的价格投标，这是您的“最高出价”。如果有人出价，雅虎会自动提高出价，直到价格达到最高出价。这叫做“自动出价": "Bid at the same price or higher, which is your 'maximum bid'. If someone bids, Yahoo will automatically increase the bid until the price reaches the maximum bid. This is called 'automatic bidding'",
    "如果您是唯一的出价者，而没有人出价，您将以最初的当前价格获胜": "If you are the only bidder and no one bids, you will win at the initial current price",
    "如果拍卖的卖家设置“最低赢得价格”，您的出价必须高于最低价格": "If the auction seller sets a 'minimum winning price', your bid must be higher than the minimum price",
    "当我和其他竞拍者同时出价时，会出现什么情况": "What happens when I bid at the same time as other bidders",
    "以相同价格或更高的价格投标。雅虎自动将其超过其他投标人设定为最高出价的价格，而当前价格相应上升。 除非您超标，否则无法找到其他投标人的最高出价": "Bid at the same price or higher. Yahoo will automatically set it higher than other bidders' maximum bid, and the current price will rise accordingly. Unless you outbid, you cannot find the other bidders' maximum bid",
    "官方邮箱": "Official email",
    "感谢您在此购物, 如果有其他问题请点击 联系客服": "Thank you for shopping here, if you have any other questions, please click Contact Customer Service",
    "发送验证码": "Send verification code",
    "目的国家": "Destination country",
    "产品名称": "Product name",
    "日元价格(每件)": "Price in yen (per item)",
    "数量": "Quantity",
    "其他": "Other",
    "提交": "Submit",
    "选择目的地": "Select destination",
    "包裹重量": "Parcel weight",
    "长度": "Length",
    "宽度": "Width",
    "高度": "Height",
    "计算": "Calculate",
    "计算结果": "Calculation result",
    "估计价格": "Estimated price",
    "运输时效": "Shipping time",
    "商品图片有可能与实物不同，请务必详细阅读商品说明内容": "The product image may differ from the actual product, please be sure to read the product description carefully",
    "店铺名称": "Store name",
    "商品状态": "Product condition",
    "非常新": "Very new",
    "商品原页面": "Original product page",
    "官网上的商品页面": "Product page on the official website",
    "颜色": "Color",
    "型号": "Model",
    "库存件": "Stock pieces",
    "备注": "Remarks",
    "加入购物车": "Add to cart",
    "友情提示：商品简介由系统自动翻译完成，内容由商家填写，esenjapan无权负责，如果您对此商品有任何疑问，请一定联系我们。您也可点击查看商品右侧的原始网页": "Friendly reminder: The product description is automatically translated by the system, the content is filled in by the merchant, esenjapan is not responsible. If you have any questions about this product, please be sure to contact us. You can also click to view the original webpage on the right side of the product",
    "商品详情": "Product details",
    "国际运费估算": "International shipping cost estimation",
    "新动态": "New dynamics",
    "用户评价": "User reviews",
    "筛选区": "Filter area",
    "排序": "Sort",
    "筛选": "Filter",
    "价格": "Price",
    "订阅内容确认": "Subscription content confirmation",
    "标准购物方案（每个链接": "Standard shopping plan (per link",
    "拍照服务（每个商品": "Photo service (per item",
    "优惠券": "Coupon",
    "保存": "Save",
    "已优惠金额：{{ discountedPrice }}元": "Discounted amount: {{ discountedPrice }} yuan",
    "如优惠券折扣未有反映，请停下付款并联通截图与客户服务中心联络": "If the coupon discount is not reflected, please stop the payment and contact the customer service center with a screenshot",
    "当所订购的复数商品中，出现售罄或购买、数量限制时": "When the ordered multiple items are out of stock or have purchase/quantity restrictions",
    "仅购买当下可订购的商品或数量（包含可调货/预约商品": "Only purchase the currently available items or quantities (including items that can be ordered/reserved)",
    "取消所有同时付款的订单（不包含可调货/预约商品": "Cancel all orders paid at the same time (excluding items that can be ordered/reserved)",
    "商品一览表": "Product list",
    "商品价格*数量": "Product price * quantity",
    "订购内容": "Order content",
    "商品金额": "Product amount",
    "购物方案费用": "Shopping plan fee",
    "订购": "Order",
    "运费及其他费用": "Shipping and other fees",
    "未确定": "Not determined",
    "日本国内运费": "Japan postage",
    "包装后确定": "Determined after packaging",
    "根据商品不同，有可能产生其他费用": "Depending on the product, there may be other fees",
    "本站服务条款": "Site terms of service",
    "订购的须知(重要": "Important ordering information",
    "订购前须知（重要": "Important information before ordering",
    "部分商品无法提供国际寄送服务，请务必在正式付款前确认": "Some items cannot be shipped internationally, please confirm before making the payment",
    "点击此处查看禁运品一览表": "Click here to view the list of prohibited items",
    "本公司无法保证商品的真伪。如出现因诈骗而未能收到货物的情况，公司会给予全额保证": "Our company cannot guarantee the authenticity of the items. In the event of fraud and failure to receive the goods, the company will provide a full guarantee",
    "请注意，一旦付款成功，订单便无法取消": "Please note that once the payment is successful, the order cannot be cancelled",
    "本人已阅读服务条款，今后不需要重复提示": "I have read the terms of service, no need to repeat the prompt in the future",
    "账号管理": "Account management",
    "邮箱": "Email",
    "更改": "Change",
    "设为默认地址": "Set as default address",
    "代购手续费": "Agency fee",
    "如果您遇到紧急问题，请点击自助服务解决问题": "If you encounter an urgent issue, please click self-service to resolve the issue",
    "部分问题对应时间：周一至周五 日本时间": "Some issues response time: Monday to Friday, Japan time",
    "自助服务": "Self-service",
    "您的问题": "Your question",
    "客服回复": "Customer service reply",
    "指定付款": "Designated payment",
    "日期": "Date",
    "操作金额": "Operation amount",
    "余额": "Balance",
    "尽在": "All in",
    "打印订单": "Print order",
    "国际邮费": "International postage",
    "加固费用": "Reinforcement fee",
    "保险额占比": "Insurance amount ratio",
    "保险费用": "Insurance fee",
    "赔偿价格": "Compensation price",
    "评论内容": "Review content",
    "商品价值": "Product value",
    "收货人信息": "Shipping information",
    "国家": "Country",
    "姓名": "Name",
    "公司": "Company",
    "公寓": "Apartment",
    "市 省": "City/Province",
    "邮编": "zip code",
    "手机号码": "Phone number",
    "返回": "Return",
    "国际快递单号": "International Tracking number",
    "创建一个包裹": "Create a package",
    "选择要创建的包裹": "Select the package to create",
    "增值服务": "Value-added services",
    "特殊加固（服务费": "Special reinforcement (service fee",
    "保险（赔偿额的": "Insurance (compensation amount",
    "其他要求": "Other requirements",
    "提交包裹": "Submit package"
}